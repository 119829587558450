import { Reducer } from 'react';
import * as Action from './SIT.actions';
import { withLoadable } from 'utils/hoc';
import { SITActionTypes, SITState } from './SIT.interfaces';

const initialState: SITState = {
  loading: false,
  error: null,
  apidaeUserInfo: null,
  tourinsoftValidData: null,
  openagendaValidData: null,
};

const reducer: Reducer<SITState, SITActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_APIDAE_INFO: {
      return {
        ...state,
        email: action.payload,
      };
    }

    case Action.GET_APIDAE_INFO_SUCCESS: {
      return {
        ...state,
        apidaeUserInfo: action.payload,
      };
    }

    case Action.GET_APIDAE_INFO_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Action.GET_TOURINSOFT_INFO: {
      return {
        ...state,
        clientDomain: action.payload.clientDomain,
        syndicationId: action.payload.syndicationId,
      };
    }

    case Action.GET_TOURINSOFT_INFO_SUCCESS: {
      return {
        ...state,
        tourinsoftValidData: true,
      };
    }

    case Action.GET_TOURINSOFT_INFO_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Action.GET_OPENAGENDA_INFO: {
      return {
        ...state,
        agendaUid: action.payload.agendaUid,
        publicKey: action.payload.publicKey,
      };
    }

    case Action.GET_OPENAGENDA_INFO_SUCCESS: {
      return {
        ...state,
        openagendaValidData: true,
      };
    }

    case Action.GET_OPENAGENDA_INFO_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    case Action.CLEAR_SIT_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};

export const sitReducer = withLoadable([
  Action.GET_APIDAE_INFO,
  Action.GET_APIDAE_INFO_SUCCESS,
  Action.GET_APIDAE_INFO_FAILURE,
  Action.GET_TOURINSOFT_INFO,
  Action.GET_TOURINSOFT_INFO_SUCCESS,
  Action.GET_TOURINSOFT_INFO_FAILURE,
  Action.GET_OPENAGENDA_INFO,
  Action.GET_OPENAGENDA_INFO_SUCCESS,
  Action.GET_OPENAGENDA_INFO_FAILURE,
])(reducer);
