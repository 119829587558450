import React from 'react';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { RenderMenuList } from 'components/MenuList';
import { IoIosClose } from 'react-icons/io';
import { ROUTES } from 'constant';
import { useWindowSize } from 'utils/hooks';
import { isMobile, isTablet } from 'utils/helpers';
import { IMenuList } from 'interfaces';
import { CloseDrawer } from './Header.style';

interface IAuthMenu {
  className?: string;
  onClose: () => void;
}

export const AuthMenu = ({ className, onClose }: IAuthMenu) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isSmallScreen = isMobile(width) || isTablet(width);

  const MenuList: IMenuList[] = [
    {
      key: 'LOGIN_PAGE',
      to: ROUTES.AUTH.LOGIN_PAGE,
      onClick: onClose,
      title: t('Sign in'),
      className: 'behat_login',
    },
    {
      key: 'REGISTRATION_PAGE',
      to: ROUTES.AUTH.REGISTRATION_PAGE,
      onClick: onClose,
      title: t('Sign up'),
      className: 'behat_register',
    },
  ];

  return (
    <Menu className={className}>
      {RenderMenuList(MenuList)}
      {isSmallScreen && (
        <CloseDrawer className="close-btn">
          <button onClick={onClose}>
            <IoIosClose />
          </button>
        </CloseDrawer>
      )}
    </Menu>
  );
};
