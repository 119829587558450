import React from 'react';
import { Link } from 'react-router-dom';
import LogoArea from './Logo.style';
import defaultTheme from '../../assets/styles';
import { FaUserCircle } from 'react-icons/fa';
import { ProfileAvatar } from '../Navbar/Navbar.style';

interface ILogo {
  className?: string;
  withLink: boolean;
  src?: string;
  title?: string;
  linkTo?: string;
}

export const Logo = ({ className, withLink, linkTo, src }: ILogo): JSX.Element => (
  <LogoArea className={className}>
    {withLink ? (
      <Link to={linkTo || '/'}>{src && <img loading="lazy" src={src} alt="Logo" />}</Link>
    ) : (
      <ProfileAvatar>
        {src ? <img loading="lazy" src={src} alt="Logo" /> : <FaUserCircle color={defaultTheme.primary[0]} />}
      </ProfileAvatar>
    )}
  </LogoArea>
);
