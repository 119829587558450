import { Reducer } from 'react';
import * as Action from './SEOLinking.actions';
import { withLoadable } from 'utils/hoc';
import { SEOLinksState, SEOLinksActionTypes } from './SEOLinking.interfaces';

const initialState: SEOLinksState = {
  loading: false,
  error: null,
  regions: null,
};

const reducer: Reducer<SEOLinksState, SEOLinksActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Action.GET_SEO_LINKS_SUCCESS: {
      return {
        ...state,
        regions: action.payload,
      };
    }

    case Action.GET_SEO_LINKS_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export const seoLinksReducer = withLoadable([
  Action.GET_SEO_LINKS,
  Action.GET_SEO_LINKS_SUCCESS,
  Action.GET_SEO_LINKS_FAILURE,
])(reducer);
