import { combineReducers } from 'redux';
import { LOG_OUT } from 'modules/Auth/Auth.actions';
import { authReducer } from 'modules/Auth/Auth.reducer';
import { eventsReducer } from 'modules/Events/Events.reducer';
import { profileReducer } from 'modules/Profile/Profile.reducer';
import { usersReducer } from 'modules/AdminPanel/AdminPanel.reducer';
import { pricingReducer } from 'modules/Pricing/Pricing.reducer';
import { sitReducer } from 'modules/SIT/SIT.reducer';
import { seoLinksReducer } from 'modules/SEOLinking/SEOLinking.reducer';
import { AppActions } from 'interfaces';
import globalReducer from 'modules/Global/Global.reducer';
import { statisticsReducer } from '../modules/Statistics/Statistics.reducer';

const appReducer = combineReducers({
  authReducer,
  eventsReducer,
  profileReducer,
  usersReducer,
  pricingReducer,
  sitReducer,
  globalReducer,
  seoLinksReducer,
  statisticsReducer,
});

const rootReducer = (state: any, action: AppActions) => {
  if (action.type === LOG_OUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
