import styled, { css } from 'styled-components';

export interface IContainer {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  noGutter?: boolean;
  withPaddingTop?: boolean;
  centerContent?: boolean;
  withFullBorder?: boolean;
}

export const ContainerWrapper = styled.div<IContainer>`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;

  ${(props) =>
    props.withPaddingTop &&
    css`
      padding: 30px 0;
    `};
  ${(props) =>
    props.centerContent &&
    css`
      align-items: center;
      justify-items: center;
      justify-content: center;
    `};

  @media (max-width: 480px) {
    max-width: 90%;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    max-width: 90%;
  }
  @media (min-width: 768px) {
    width: 100%;
    max-width: 750px;
  }
  @media (min-width: 970px) {
    width: 100%;
    max-width: 950px;
  }
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 1170px;
  }
  @media (min-width: 1600px) {
    width: 100%;
    max-width: 1470px;
  }

  ${(props) =>
    props.withFullBorder &&
    css`
      @media (max-width: 750px) {
        width: 100%;
        max-width: 100%;
      }
      @media (min-width: 480px) and (max-width: 768px) {
        max-width: 100%;
      }
    `};
`;
