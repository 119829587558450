import { IUser } from 'interfaces';
import { axiosInstance } from 'services/api';
import { axiosInstance as anonAxiosInstance } from 'services/anonApi';
import {
  IGetEmailActivate,
  IPostAuthLogin,
  IPostAuthLoginSuccess,
  IPostAuthRegister,
  IPostEmailResetPassword,
  IPostResetPassword,
  IPostSocialAuth,
  IPostSocialAuthSuccess,
  IPostTokenVerify,
} from './Auth.interfaces';

export default {
  async postAuthLogin(data: IPostAuthLogin) {
    return anonAxiosInstance.post<IPostAuthLoginSuccess>('login/', data);
  },
  async postAuthRegister(data: IPostAuthRegister) {
    return anonAxiosInstance.post<IUser>('user/', data);
  },
  async postSocialAuth(data: IPostSocialAuth) {
    return anonAxiosInstance.post<IPostSocialAuthSuccess>('social/login/', data);
  },
  async postEmailResetPassword(data: IPostEmailResetPassword) {
    return anonAxiosInstance.post<IPostEmailResetPassword>('email-reset-password/', data);
  },
  async postResetPassword(data: IPostResetPassword) {
    return anonAxiosInstance.post('reset-password/', data.data);
  },
  async getEmailActivate(query: IGetEmailActivate) {
    return anonAxiosInstance.get('email-activate/', { params: query });
  },
  async postTokenVerify(data: IPostTokenVerify) {
    return axiosInstance.post<IPostTokenVerify>('token/verify/', data);
  },
};
