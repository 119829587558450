export * from './Routes';
export * from './Events';
export * from './Maps';

const overrides: any = {
  anto: {
    signInTitle: 'Anto',
    logoWhite: '/images/anto/logo_text_white.svg',
    logo: '/images/anto/logo.svg',
    authenticationBackground: '/images/anto/authentication_background.png',
    authenticationBackgroundOverlay: '/images/anto/authentication_background_overlay.svg',
    lottieAnimation: '/animations/anto/data.json',
    lottieAudio: '/animations/anto/audio.mp3',
    profileIcon: '/images/anto/profile_icon.svg',
  },
  mntp: {
    signInTitle: 'Montpellier Agenda',
    logoWhite: '/images/mntp/logo_white.svg',
    logo: '/images/mntp/logo.png',
    authenticationBackground: '/images/mntp/authentication_background.svg',
    authenticationBackgroundOverlay: '/images/mntp/authentication_background_overlay.svg',
    lottieAnimation: '/animations/mntp/data.json',
    profileIcon: '/images/mntp/profile_icon.svg',
  },
};

const currentEnvName: string = process.env.REACT_APP_ENV || 'anto';

const envVariables = overrides[currentEnvName] || {};

export default envVariables;
