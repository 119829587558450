export const format = 'gif';
export const mobSize = '400x300';
export const tabletSize = '700x300';
export const desktopSize = '800x250';
export const scale = 2;
export const mobileZoom = 17;
export const desktopZoom = 15;
export const cityTypes = [
  'locality',
  'administrative_area_level_1',
  'administrative_area_level_2',
  'administrative_area_level_3',
  'administrative_area_level_4',
  'administrative_area_level_5',
];
