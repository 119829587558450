import { axiosInstance } from 'services/api';
import {
  IComment,
  IEntity,
  IEvent,
  IFilterEventsParams,
  IFilterTags,
  IFilterUsers,
  IPack,
  IPlan,
  ITag,
  IUser,
  IZone,
} from 'interfaces';
import { IEntitiesPageFilters } from './AdminPanel.interfaces';

export default {
  async getUsers(query: IFilterUsers) {
    return axiosInstance.get<IUser[]>('user/', { params: query });
  },
  async getEntities(query: IEntitiesPageFilters) {
    return axiosInstance.get<IEntity[]>('entity/', { params: query });
  },
  async deactivateUser(user_id: number) {
    return axiosInstance.delete(`user/${user_id}/`);
  },
  async deactivateEntity(id: number) {
    return axiosInstance.delete(`entity/${id}/`);
  },
  async getEntity(id: number) {
    return axiosInstance.get(`entity/${id}/`);
  },
  async createEntity(data: IEntity) {
    return axiosInstance.post(`entity/`, data);
  },
  async postCreateUser(data: IUser) {
    return axiosInstance.post<IUser>('user/', data);
  },
  async getUser(query: IFilterUsers) {
    return axiosInstance.get<IUser>(`user/${query.id}/`);
  },
  async patchUpdateUser(data: IUser) {
    return axiosInstance.patch<IUser>(`user/${data.id}/`, data);
  },
  async getPastEvents(query: IFilterEventsParams) {
    return axiosInstance.get<IEvent[]>('event/for-admin-past/', { params: query });
  },
  async getUpcomingEvents(query: IFilterEventsParams) {
    return axiosInstance.get<IEvent[]>('event/for-admin-upcoming/', { params: query });
  },
  async getAllTags(query: IFilterTags) {
    return axiosInstance.get<ITag[]>('event/tag/', { params: query });
  },
  async deleteTag(id: number) {
    return axiosInstance.delete(`event/tag/${id}/`);
  },
  async createTag(data: { name: string }) {
    return axiosInstance.post<ITag>('event/tag/', data);
  },
  async updateTag(data: ITag) {
    return axiosInstance.patch(`event/tag/${data.id}/`, data);
  },
  async getZones(query: { page?: number; ordering?: string; search?: string; departments?: number }) {
    return axiosInstance.get<IZone[]>('event/zone/', { params: query });
  },
  async getPlans() {
    return axiosInstance.get('payment/plan/');
  },
  async createPlan(data: IPlan) {
    return axiosInstance.post('payment/plan/', data);
  },
  async updatePlan(data: IPlan) {
    return axiosInstance.patch(`payment/plan/${data.id}/`, data);
  },
  async deletePlan(id: number) {
    return axiosInstance.delete(`payment/plan/${id}/`);
  },
  async getPacks() {
    return axiosInstance.get('payment/pack/');
  },
  async createPack(data: IPack) {
    return axiosInstance.post('payment/pack/', data);
  },
  async updatePack(data: IPack) {
    return axiosInstance.patch(`payment/pack/${data.id}/`, data);
  },
  async deletePack(id: number) {
    return axiosInstance.delete(`payment/pack/${id}/`);
  },
  async uploadCsv(data: FormData) {
    return axiosInstance.post('event/csv/', data);
  },
  async addEntityCredits(data: { id: number; credits: number }) {
    return axiosInstance.patch(`entity/${data.id}/add-credits/`, { credits: data.credits });
  },
  async getAllComments(query: { page: number }) {
    return axiosInstance.get<IComment[]>('event/comment/', { params: query });
  },
  async deleteComment(id: number) {
    return axiosInstance.delete(`event/comment/${id}/`);
  },
  async verifyAlias(alias: string) {
    return axiosInstance.post('entity/verify-alias/', { alias: alias });
  },
};
