import React from 'react';

export const PauseIcon = ({ fill }: { fill: string }) => (
  <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M35.4401 23.16C27.6101 23.69 27.6101 66.65 35.3101 67.16C43.1401 66.65 43.1401 23.69 35.4401 23.16Z"
        fill={fill}
      />
      <path
        d="M53.6501 23.16C45.8201 23.69 45.8201 66.65 53.5201 67.16C61.3501 66.65 61.3501 23.69 53.6501 23.16Z"
        fill={fill}
      />
      <path
        d="M77.79 32.02C76.1471 27.5801 73.6077 23.526 70.33 20.11C67.0582 16.6033 63.1039 13.8026 58.71 11.88C54.5307 10.0497 50.0317 9.06092 45.47 8.97001C40.8771 8.90119 36.3128 9.70544 32.02 11.34C27.5499 13.0086 23.4668 15.5707 20.02 18.87C16.5692 22.1162 13.8283 26.042 11.97 30.4C8.29567 38.7908 8.04094 48.2843 11.26 56.86C12.945 61.3382 15.5318 65.4226 18.86 68.86C22.0885 72.3238 25.9966 75.0846 30.34 76.97C34.526 78.7812 39.0122 79.7985 43.57 79.97C48.2114 80.1376 52.8392 79.3726 57.1797 77.7202C61.5202 76.0679 65.4852 73.5617 68.84 70.35C72.3016 67.0996 75.0674 63.1805 76.97 58.83C78.8281 54.6102 79.847 50.0691 79.97 45.46C80.1076 40.882 79.3676 36.3199 77.79 32.02ZM75.21 45.33C74.9736 53.5098 71.4991 61.261 65.55 66.88C62.6476 69.652 59.2204 71.8159 55.4699 73.2447C51.7194 74.6734 47.7211 75.3382 43.71 75.2C39.7682 75.0446 35.8893 74.1597 32.27 72.59C28.5155 70.9616 25.1355 68.579 22.34 65.59C19.4628 62.6064 17.2299 59.063 15.78 55.18C12.9821 47.7673 13.2011 39.5531 16.39 32.3C18.015 28.5435 20.398 25.1629 23.39 22.37C26.3629 19.523 29.8845 17.3114 33.74 15.87C37.4475 14.4529 41.3915 13.7571 45.36 13.82C51.4636 13.9854 57.3794 15.9658 62.3524 19.5085C67.3253 23.0512 71.1297 27.9954 73.28 33.71C74.6601 37.4244 75.3153 41.3689 75.21 45.33V45.33Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="71.03" height="71.03" fill="white" transform="translate(9 9)" />
      </clipPath>
    </defs>
  </svg>
);
