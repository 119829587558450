import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { history } from 'utils/history';
import i18n from 'i18n';
import { getPermissions, getUserInfo, handleError, showSuccess, updateUserInfo } from 'utils/helpers';
import * as ActionTypes from './AdminPanel.actions';
import * as ActionInterface from './AdminPanel.interfaces';
import Api from './AdminPanel.api';
import { ROUTES } from 'constant';
import ProfileApi from '../Profile/Profile.api';
import { BASE_USER, CONTRIBUTOR } from '../../interfaces';

function* getUser({ payload }: ActionInterface.GetUserAction) {
  try {
    const { data } = yield call(Api.getUser, payload.query);
    yield put(ActionTypes.getUser.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getUser.failure(e));
  }
}

function* getUserSaga() {
  yield takeLatest(ActionTypes.GET_USER, getUser);
}

function* getUsers({ payload }: ActionInterface.GetUsersAction) {
  try {
    const { data } = yield call(Api.getUsers, payload.query);
    yield put(ActionTypes.getUsers.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getUsers.failure(e));
  }
}

function* getUsersSaga() {
  yield takeLatest(ActionTypes.GET_USERS, getUsers);
}

function* deactivateUser({ payload }: ActionInterface.DeactivateUserAction) {
  try {
    const { data } = yield call(Api.deactivateUser, payload);
    yield put(ActionTypes.deactivateUser.success(data));
    yield put(ActionTypes.getUsers.request({ query: { page: 1 } }));
    showSuccess({ text: i18n.t('User deactivated successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deactivateUser.failure(e));
  }
}

function* deactivateUserSaga() {
  yield takeLatest(ActionTypes.DEACTIVATE_USER, deactivateUser);
}

function* deactivateEntity({ payload }: ActionInterface.DeactivateEntityAction) {
  const permission = getPermissions();
  try {
    const { data } = yield call(Api.deactivateEntity, payload);
    yield put(ActionTypes.deactivateEntity.success(payload));
    showSuccess({ text: i18n.t('Entity deactivated successfully') });
    if (permission.contributor) {
      const user = getUserInfo();
      user.role = BASE_USER;
      user.entity = data;
      updateUserInfo(user);
      history.push(ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE);
    }
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deactivateEntity.failure(e));
  }
}

function* deactivateEntitySaga() {
  yield takeLatest(ActionTypes.DEACTIVATE_ENTITY, deactivateEntity);
}

function* activateEntity({ payload }: ActionInterface.ActivateEntityAction) {
  try {
    const formData = new FormData();
    formData.set('is_active', 'true');
    yield call(ProfileApi.updateEntity, { id: payload, data: formData });
    yield put(ActionTypes.activateEntity.success(payload));
    showSuccess({ text: i18n.t('Entity activated successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.activateEntity.failure(e));
  }
}

function* activateEntitySaga() {
  yield takeLatest(ActionTypes.ACTIVATE_ENTITY, activateEntity);
}

function* createEntity({ payload }: ActionInterface.CreateEntityAction) {
  const permission = getPermissions();
  try {
    const { data } = yield call(Api.createEntity, payload);
    if (permission.baseUser) {
      const currentUser = getUserInfo();
      currentUser.entity = data;
      currentUser.role = CONTRIBUTOR;
      updateUserInfo(currentUser);
    } else {
      history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.ENTITIES_ADMIN_PANEL}`);
    }
    yield put(ActionTypes.createEntity.success(payload));
    showSuccess({ text: i18n.t('Entity created successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.createEntity.failure(e));
  }
}

function* createEntitySaga() {
  yield takeLatest(ActionTypes.CREATE_ENTITY, createEntity);
}

function* postCreateUser({ payload }: ActionInterface.PostCreateUserAction) {
  try {
    const { data } = yield call(Api.postCreateUser, payload);
    yield put(ActionTypes.postCreateUser.success(data));
    history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.USERS_ADMIN_PANEL}`);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postCreateUser.failure(e));
  }
}

function* postCreateUserSaga() {
  yield takeLatest(ActionTypes.POST_CREATE_USER, postCreateUser);
}

function* patchUpdateUser({ payload }: ActionInterface.PatchUpdateUserAction) {
  try {
    yield call(Api.patchUpdateUser, payload);
    showSuccess({ text: i18n.t('User updated successfully') });
    history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.USERS_ADMIN_PANEL}`);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.patchUpdateUser.failure(e));
  }
}

function* patchUpdateUserSaga() {
  yield takeLatest(ActionTypes.PATCH_UPDATE_USER, patchUpdateUser);
}

function* getAllTags({ payload }: ActionInterface.GetAllTagsAction) {
  try {
    const { data } = yield call(Api.getAllTags, payload.query);
    yield put(ActionTypes.getAllTags.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getAllTags.failure(e));
  }
}

function* getAllTagsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_TAGS, getAllTags);
}

function* deleteTag({ payload }: ActionInterface.DeleteTagAction) {
  try {
    const { data } = yield call(Api.deleteTag, payload.id);
    yield put(ActionTypes.deleteTag.success(data));
    yield put(ActionTypes.getAllTags.request({ query: { page: 1 } }));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deleteTag.failure(e));
  }
}

function* deleteTagSaga() {
  yield takeLatest(ActionTypes.DELETE_TAG, deleteTag);
}

function* createTag({ payload }: ActionInterface.CreateTagAction) {
  try {
    const { data } = yield call(Api.createTag, payload);
    yield put(ActionTypes.createTag.success(data));
    yield put(ActionTypes.getAllTags.request({ query: { page: 1 } }));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.createTag.failure(e));
  }
}

function* createTagSaga() {
  yield takeLatest(ActionTypes.CREATE_TAG, createTag);
}

function* updateTag({ payload }: ActionInterface.UpdateTagAction) {
  try {
    const { data } = yield call(Api.updateTag, payload.data);
    yield put(ActionTypes.updateTag.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.updateTag.failure(e));
  }
}

function* updateTagSaga() {
  yield takeLatest(ActionTypes.UPDATE_TAG, updateTag);
}

function* getZones({ payload }: ActionInterface.GetZonesAction) {
  try {
    const { data } = yield call(Api.getZones, payload.data);
    yield put(ActionTypes.getZones.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getZones.failure(e));
  }
}

function* getZonesSaga() {
  yield takeLatest(ActionTypes.GET_ZONES, getZones);
}

function* getPlans() {
  try {
    const { data } = yield call(Api.getPlans);
    yield put(ActionTypes.getPlans.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getPlans.failure(e));
  }
}

function* getPlansSaga() {
  yield takeLatest(ActionTypes.GET_ALL_PLANS, getPlans);
}

function* createPlan({ payload }: ActionInterface.CreatePlanAction) {
  try {
    const { data } = yield call(Api.createPlan, payload.data);
    yield put(ActionTypes.createPlan.success(data));
    yield put(ActionTypes.getPlans.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.createPlan.failure(e));
  }
}

function* createPlanSaga() {
  yield takeLatest(ActionTypes.CREATE_PLAN, createPlan);
}

function* updatePlan({ payload }: ActionInterface.UpdatePlanAction) {
  try {
    const { data } = yield call(Api.updatePlan, payload.data);
    yield put(ActionTypes.updatePlan.success(data));
    yield put(ActionTypes.getPlans.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.updatePlan.failure(e));
  }
}

function* updatePlanSaga() {
  yield takeLatest(ActionTypes.PATCH_PLAN, updatePlan);
}

function* deletePlan({ payload }: ActionInterface.DeletePlanAction) {
  try {
    const { data } = yield call(Api.deletePlan, payload.id);
    yield put(ActionTypes.deletePlan.success(data));
    yield put(ActionTypes.getPlans.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deletePlan.failure(e));
  }
}

function* deletePlanSaga() {
  yield takeLatest(ActionTypes.DELETE_PLAN, deletePlan);
}

function* getPacks() {
  try {
    const { data } = yield call(Api.getPacks);
    yield put(ActionTypes.getPacks.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getPacks.failure(e));
  }
}

function* getPacksSaga() {
  yield takeLatest(ActionTypes.GET_PACKS, getPacks);
}

function* createPack({ payload }: ActionInterface.CreatePackAction) {
  try {
    const { data } = yield call(Api.createPack, payload.data);
    yield put(ActionTypes.createPack.success(data));
    yield put(ActionTypes.getPacks.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.createPack.failure(e));
  }
}

function* createPackSaga() {
  yield takeLatest(ActionTypes.CREATE_PACK, createPack);
}

function* updatePack({ payload }: ActionInterface.UpdatePackAction) {
  try {
    const { data } = yield call(Api.updatePack, payload.data);
    yield put(ActionTypes.updatePack.success(data));
    yield put(ActionTypes.getPacks.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.updatePack.failure(e));
  }
}

function* updatePackSaga() {
  yield takeLatest(ActionTypes.PATCH_PACK, updatePack);
}

function* deletePack({ payload }: ActionInterface.DeletePackAction) {
  try {
    const { data } = yield call(Api.deletePack, payload.id);
    yield put(ActionTypes.deletePack.success(data));
    yield put(ActionTypes.getPacks.request());
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deletePack.failure(e));
  }
}

function* deletePackSaga() {
  yield takeLatest(ActionTypes.DELETE_PACK, deletePack);
}

function* uploadCsv({ payload }: ActionInterface.UploadCsvAction) {
  try {
    const { data } = yield call(Api.uploadCsv, payload.data);
    yield put(ActionTypes.uploadCsv.success(data));
    showSuccess({
      text: i18n.t('You can edit your event in Your Events tab'),
      title: i18n.t('Uploaded Successfully'),
    });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.uploadCsv.failure(e));
  }
}

function* uploadCsvSaga() {
  yield takeLatest(ActionTypes.UPLOAD_CSV, uploadCsv);
}
function* getEntities({ payload }: ActionInterface.GetEntitiesAction) {
  try {
    const { data } = yield call(Api.getEntities, payload);
    yield put(ActionTypes.getEntities.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getEntities.failure(e));
  }
}

function* getEntitiesSaga() {
  yield takeLatest(ActionTypes.GET_ENTITIES, getEntities);
}
function* addEntityCredits({ payload }: ActionInterface.AddEntityCreditsAction) {
  try {
    yield call(Api.addEntityCredits, payload);
    yield put(ActionTypes.addEntityCredits.success(payload));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.addEntityCredits.failure(e));
  }
}

function* addEntityCreditsSaga() {
  yield takeLatest(ActionTypes.ADD_ENTITY_CREDITS, addEntityCredits);
}

function* getAllComments({ payload }: ActionInterface.GetAllCommentsAction) {
  try {
    const { data } = yield call(Api.getAllComments, payload.query);
    yield put(ActionTypes.getAllComments.success(data));
  } catch (e) {
    handleError(e);
    console.log(e);
    yield put(ActionTypes.getAllComments.failure(e));
  }
}

function* getAllCommentsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_COMMENTS, getAllComments);
}

function* deleteComment({ payload }: ActionInterface.DeleteCommentAction) {
  try {
    const { data } = yield call(Api.deleteComment, payload.id);
    yield put(ActionTypes.deleteComment.success(data));
    showSuccess({ text: i18n.t('Comment deleted successfully') });
    yield put(ActionTypes.getAllComments.request({ query: { page: 1 } }));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deleteComment.failure(e));
  }
}

function* deleteCommentSaga() {
  yield takeLatest(ActionTypes.ADMIN_DELETE_COMMENT, deleteComment);
}

function* getPastEvents({ payload }: ActionInterface.GetPastEventsAction) {
  try {
    const { data } = yield call(Api.getPastEvents, payload.query);
    yield put(ActionTypes.getPastEvents.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getPastEvents.failure(e));
  }
}

function* getPastEventsSaga() {
  yield takeLatest(ActionTypes.GET_PAST_EVENTS, getPastEvents);
}

function* getUpcomingEvents({ payload }: ActionInterface.GetUpcomingEventsAction) {
  try {
    const { data } = yield call(Api.getUpcomingEvents, payload.query);
    yield put(ActionTypes.getUpcomingEvents.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getUpcomingEvents.failure(e));
  }
}

function* getUpcomingEventsSaga() {
  yield takeLatest(ActionTypes.GET_UPCOMING_EVENTS, getUpcomingEvents);
}

function* getEntityToEdit({ payload }: ActionInterface.GetEntityToEditAction) {
  try {
    const { data } = yield call(Api.getEntity, payload);
    yield put(ActionTypes.getEntityToEdit.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getEntityToEdit.failure(e));
  }
}

function* getEntityToEditSaga() {
  yield takeLatest(ActionTypes.GET_ENTITY_TO_EDIT, getEntityToEdit);
}

function* verifyAlias({ payload }: ActionInterface.VerifyAliasAction) {
  try {
    const { data } = yield call(Api.verifyAlias, payload);
    yield put(ActionTypes.verifyAlias.success(data));
  } catch (e) {
    yield put(ActionTypes.verifyAlias.failure(e.response.data.error));
  }
}

function* verifyAliasSaga() {
  yield takeLatest(ActionTypes.VERIFY_ALIAS, verifyAlias);
}

export const usersSaga = function* rootSaga() {
  yield all([
    fork(getUsersSaga),
    fork(deactivateUserSaga),
    fork(deactivateEntitySaga),
    fork(activateEntitySaga),
    fork(postCreateUserSaga),
    fork(getUserSaga),
    fork(patchUpdateUserSaga),
    fork(getAllTagsSaga),
    fork(deleteTagSaga),
    fork(createTagSaga),
    fork(getZonesSaga),
    fork(updateTagSaga),
    fork(getPlansSaga),
    fork(getPacksSaga),
    fork(updatePlanSaga),
    fork(deletePlanSaga),
    fork(updatePackSaga),
    fork(deletePackSaga),
    fork(createPackSaga),
    fork(createPlanSaga),
    fork(uploadCsvSaga),
    fork(addEntityCreditsSaga),
    fork(getAllCommentsSaga),
    fork(deleteCommentSaga),
    fork(getPastEventsSaga),
    fork(getUpcomingEventsSaga),
    fork(getEntitiesSaga),
    fork(createEntitySaga),
    fork(getEntityToEditSaga),
    fork(verifyAliasSaga),
  ]);
};
