import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18n';
import {
  getPermissions,
  getUserInfo,
  handleError,
  objectToFormData,
  showSuccess,
  srcToFile,
  updateUserInfo,
} from 'utils/helpers';
import * as ActionTypes from './Events.actions';
import * as AdminActionTypes from '../AdminPanel/AdminPanel.actions';
import * as ProfileActionTypes from '../Profile/Profile.actions';
import * as Interfaces from './Event.interfaces';
import Api from './Events.api';
import { IEventDetails, IPermissions } from '../../interfaces';
import { history } from '../../utils/history';
import { ROUTES } from '../../constant';
import Swal from 'sweetalert2';
import defaultTheme from '../../assets/styles';

function* getEventsList({ payload }: Interfaces.GetEventsAction) {
  try {
    const user = getUserInfo();
    if (user.favorite) {
      yield put(ActionTypes.getFavorites.success(user.favorites));
    }
    const { data } = yield call(Api.getEventsList, payload.query);
    yield put(ActionTypes.getEventsList.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getEventsList.failure(e));
  }
}

function* getEventsListSaga() {
  yield takeLatest(ActionTypes.GET_EVENTS, getEventsList);
}

function* getDetailedEvents({ payload }: Interfaces.GetDetailedEventsAction) {
  try {
    const { data } = yield call(Api.getDetailedEvents, payload.query);
    yield put(ActionTypes.getDetailedEvents.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getDetailedEvents.failure(e));
  }
}

function* getDetailedEventsSaga() {
  yield takeLatest(ActionTypes.GET_DETAILED_EVENTS, getDetailedEvents);
}

function* getSingleEvent({ payload }: Interfaces.GetSingleEventAction) {
  try {
    const { data } = yield call(Api.getSingleEvent, payload);
    yield put(ActionTypes.getSingleEvent.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getSingleEvent.failure(e));
  }
}

function* getSingleEventSaga() {
  yield takeLatest(ActionTypes.GET_SINGLE_EVENT, getSingleEvent);
}

function* getOneEvent({ payload }: Interfaces.GetOneEventAction) {
  try {
    const { data } = yield call(Api.getOneEvent, payload.id);
    if (payload.toDuplicate) {
      if (data.photo_url) {
        const photo: File = yield call(srcToFile, data.photo_url, `img-${data.id}.jpeg`, 'image/jpeg');
        if (photo) {
          data.image = photo;
        }
      }
      data.id = undefined;
      data.details = data.details.map((de: IEventDetails) => {
        const { title, description, language_code } = de;
        return { title, description, language_code };
      });
    }
    yield put(ActionTypes.getOneEvent.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getOneEvent.failure(e));
  }
}

function* getOneEventSaga() {
  yield takeLatest(ActionTypes.GET_ONE_EVENT, getOneEvent);
}

function* createEvent({ payload }: Interfaces.CreateEventAction) {
  const permission: IPermissions = getPermissions();
  try {
    const formData = objectToFormData(payload);
    const { data } = yield call(Api.createEvent, formData);
    yield put(ActionTypes.createEvent.success(data));
    showSuccess({ text: i18n.t('Event created successfully') });
    if (permission.contributor) {
      const user = getUserInfo();
      for (const eventDetail of Object.values(payload.details)) {
        if (eventDetail.production_required) {
          user.entity.count_of_credits -= 1;
        }
      }
      updateUserInfo(user);
      yield put(ProfileActionTypes.setEventsPageState({ sort: '-updated_at', tab: 'upcoming' }));
      history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.ENTITIES_EVENTS}`);
    } else {
      yield put(AdminActionTypes.setEventsPageState({ page: 1, sort: '-updated_at', tab: 'upcoming' }));
      history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.REDACTOR_PAGE}`);
    }
  } catch (e) {
    if (e.response.status === 402) {
      Swal.fire({
        text: e.response.data,
        showCancelButton: true,
        confirmButtonColor: defaultTheme.success[0],
        cancelButtonColor: defaultTheme.error[0],
        confirmButtonText: i18n.t('Change my plan'),
        cancelButtonText: i18n.t('Close'),
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(
            `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.INVOICES}${ROUTES.PROFILE.PLANS}`,
          );
        } else {
          Swal.close();
        }
      });
    } else {
      handleError(e);
    }
    yield put(ActionTypes.createEvent.failure(e));
  }
}

function* createEventSaga() {
  yield takeLatest(ActionTypes.CREATE_EVENT, createEvent);
}

function* updateEvent({ payload }: Interfaces.UpdateEventAction) {
  const permission: IPermissions = getPermissions();
  try {
    const formData = objectToFormData(payload);
    const { data } = yield call(Api.updateEvent, formData);
    yield put(ActionTypes.updateEvent.success(data));
    showSuccess({ text: i18n.t('Event updated successfully') });
    if (permission.contributor) {
      history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.ENTITIES_EVENTS}`);
    } else history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.REDACTOR_PAGE}`);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.updateEvent.failure(e));
  }
}

function* updateEventSaga() {
  yield takeLatest(ActionTypes.UPDATE_EVENT, updateEvent);
}

function* deleteEvent({ payload }: Interfaces.DeleteEventAction) {
  try {
    yield call(Api.deleteEvent, payload.id);
    yield put(ActionTypes.deleteEvent.success(payload));
    yield put({ type: AdminActionTypes.DELETE_EVENT_SUCCESS, payload: payload });
    yield put({ type: ProfileActionTypes.DELETE_EVENT_SUCCESS, payload: payload });
    showSuccess({ text: i18n.t('Event successfully deleted') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deleteEvent.failure(e));
  }
}

function* deleteEventSaga() {
  yield takeLatest(ActionTypes.DELETE_EVENT, deleteEvent);
}

function* rejectEvent({ payload }: Interfaces.RejectEventAction) {
  const permission: IPermissions = getPermissions();
  try {
    const formData = objectToFormData(payload.event);
    const { data: event } = yield call(Api.updateEvent, formData);
    yield put(ActionTypes.updateEvent.success(event));
    const { data } = yield call(Api.rejectEvent, payload);
    showSuccess({ text: i18n.t('Status changed successfully') });
    yield put({ type: AdminActionTypes.EVENT_STATUS_UPDATE_SUCCESS, payload: data });
    if (permission.contributor) {
      history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.ENTITIES_EVENTS}`);
    } else history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.REDACTOR_PAGE}`);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.rejectEvent.failure(e));
  }
}

function* rejectEventSaga() {
  yield takeLatest(ActionTypes.REJECT_EVENT, rejectEvent);
}

function* putEventOnline({ payload }: Interfaces.PutEventOnlineAction) {
  const permission: IPermissions = getPermissions();
  try {
    const formData = objectToFormData(payload);
    const { data: event } = yield call(Api.updateEvent, formData);
    yield put(ActionTypes.updateEvent.success(event));
    const { data } = yield call(Api.putEventOnline, payload.id as number);
    yield put({ type: AdminActionTypes.EVENT_STATUS_UPDATE_SUCCESS, payload: data });
    showSuccess({ text: i18n.t('Status changed successfully') });
    if (permission.contributor) {
      history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.ENTITIES_EVENTS}`);
    } else history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.REDACTOR_PAGE}`);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.putEventOnline.failure(e));
  }
}

function* putEventOnlineSaga() {
  yield takeLatest(ActionTypes.PUT_EVENT_ONLINE, putEventOnline);
}

function* putEventOffline({ payload }: Interfaces.PutEventOfflineAction) {
  const permission: IPermissions = getPermissions();
  try {
    const formData = objectToFormData(payload);
    const { data: event } = yield call(Api.updateEvent, formData);
    yield put(ActionTypes.updateEvent.success(event));
    const { data } = yield call(Api.putEventOffline, payload.id as number);
    yield put({ type: AdminActionTypes.EVENT_STATUS_UPDATE_SUCCESS, payload: data });
    showSuccess({ text: i18n.t('Status changed successfully') });
    if (permission.contributor) {
      history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.ENTITIES_EVENTS}`);
    } else history.push(`${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.ADMIN_PANEL.REDACTOR_PAGE}`);
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.putEventOffline.failure(e));
  }
}

function* putEventOfflineSaga() {
  yield takeLatest(ActionTypes.PUT_EVENT_OFFLINE, putEventOffline);
}

function* rejectEventDetail({ payload }: Interfaces.RejectEventDetailAction) {
  try {
    const { data } = yield call(Api.rejectEventDetail, payload);
    yield put({ type: AdminActionTypes.EVENT_STATUS_UPDATE_SUCCESS, payload: data });
    yield put({ type: ActionTypes.EVENT_DETAIL_STATUS_UPDATE_SUCCESS, payload: data });
    showSuccess({ text: i18n.t('Status changed successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.rejectEventDetail.failure(e));
  }
}

function* rejectEventDetailSaga() {
  yield takeLatest(ActionTypes.REJECT_EVENT_DETAIL, rejectEventDetail);
}

function* putEventDetailOnline({ payload }: Interfaces.PutEventDetailOnlineAction) {
  try {
    const { data } = yield call(Api.putEventDetailOnline, payload);
    yield put({ type: AdminActionTypes.EVENT_STATUS_UPDATE_SUCCESS, payload: data });
    yield put({ type: ActionTypes.EVENT_DETAIL_STATUS_UPDATE_SUCCESS, payload: data });
    showSuccess({ text: i18n.t('Status changed successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.putEventDetailOnline.failure(e));
  }
}

function* putEventDetailOnlineSaga() {
  yield takeLatest(ActionTypes.PUT_EVENT_DETAIL_ONLINE, putEventDetailOnline);
}

function* putEventDetailOffline({ payload }: Interfaces.PutEventDetailOfflineAction) {
  try {
    const { data } = yield call(Api.putEventDetailOffline, payload);
    yield put({ type: AdminActionTypes.EVENT_STATUS_UPDATE_SUCCESS, payload: data });
    yield put({ type: ActionTypes.EVENT_DETAIL_STATUS_UPDATE_SUCCESS, payload: data });
    showSuccess({ text: i18n.t('Status changed successfully') });
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.putEventDetailOffline.failure(e));
  }
}

function* putEventDetailOfflineSaga() {
  yield takeLatest(ActionTypes.PUT_EVENT_DETAIL_OFFLINE, putEventDetailOffline);
}

function* getContributor({ payload }: Interfaces.GetContributorAction) {
  try {
    const { data } = yield call(Api.getContributor, payload);
    yield put(ActionTypes.getContributor.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getContributor.failure(e));
  }
}

function* getContributorSaga() {
  yield takeLatest(ActionTypes.GET_CONTRIBUTOR, getContributor);
}

function* getContributorEvents({ payload }: Interfaces.GetContributorEventsAction) {
  try {
    const { data } = yield call(Api.getContributorEvents, payload.query);
    yield put(ActionTypes.getContributorEvents.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getContributorEvents.failure(e));
  }
}

function* getContributorEventsSaga() {
  yield takeLatest(ActionTypes.GET_CONTRIBUTOR_EVENTS, getContributorEvents);
}

function* getUserData() {
  try {
    const { data } = yield call(Api.getUserData);
    updateUserInfo(data);
    yield put(ActionTypes.getUserData.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.getUserData.failure(e));
  }
}

function* getUserDataSaga() {
  yield takeLatest(ActionTypes.GET_USER_DATA, getUserData);
}

function* postComment({ payload }: Interfaces.PostCommentAction) {
  try {
    const { data } = yield call(Api.postComment, payload.data);
    yield put(ActionTypes.postComment.success(data));
    yield put(ActionTypes.getSingleEvent.request({ id: data.event }));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postComment.failure(e));
  }
}

function* postCommentSaga() {
  yield takeLatest(ActionTypes.POST_COMMENT, postComment);
}

function* patchReport({ payload }: Interfaces.PatchReportAction) {
  try {
    const { data } = yield call(Api.patchReport, payload);
    yield put(ActionTypes.patchReport.success(data));
    showSuccess({ text: i18n.t('Report successfully posted.') });
    yield put(ActionTypes.getSingleEvent.request({ id: payload.id }));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.patchReport.failure(e));
  }
}

function* patchReportSaga() {
  yield takeLatest(ActionTypes.PATCH_REPORT, patchReport);
}

function* deleteComment({ payload }: Interfaces.DeleteCommentAction) {
  try {
    const { data } = yield call(Api.deleteComment, payload.id);
    yield put(ActionTypes.deleteComment.success(data));
    yield put(ActionTypes.getSingleEvent.request({ id: payload.eventId }));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deleteComment.failure(e));
  }
}

function* deleteCommentSaga() {
  yield takeLatest(ActionTypes.DELETE_COMMENT, deleteComment);
}

function* postPhonetic({ payload }: Interfaces.PostPhoneticAction) {
  try {
    const { data } = yield call(Api.postPhonetic, objectToFormData(payload));
    yield put(ActionTypes.postPhonetic.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.postPhonetic.failure(e));
  }
}

function* postPhoneticSaga() {
  yield takeLatest(ActionTypes.POST_PHONETIC, postPhonetic);
}

function* listPhonetics({}: Interfaces.ListPhoneticsAction) {
  try {
    const { data } = yield call(Api.listPhonetics);
    yield put(ActionTypes.listPhonetics.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.listPhonetics.failure(e));
  }
}

function* listPhoneticsSaga() {
  yield takeLatest(ActionTypes.LIST_PHONETICS, listPhonetics);
}

function* deletePhonetic({ payload }: Interfaces.DeletePhoneticAction) {
  try {
    yield call(Api.deletePhonetic, payload);
    yield put(ActionTypes.deletePhonetic.success(payload));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.deletePhonetic.failure(e));
  }
}

function* deletePhoneticSaga() {
  yield takeLatest(ActionTypes.DELETE_PHONETIC, deletePhonetic);
}

function* downloadUserEventsCsv({ payload }: Interfaces.DownloadUserEventsCSVAction) {
  try {
    const { data } = yield call(Api.downloadUserEventsCSV, payload);
    const date = new Date().toISOString();
    const filename = `user_events_${payload}_${date}.csv`;

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
    yield put(ActionTypes.downloadUserEventsCsv.success(payload));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.downloadUserEventsCsv.failure(e));
  }
}

function* downloadUserEventsCsvSaga() {
  yield takeLatest(ActionTypes.DOWNLOAD_USER_EVENTS_CSV, downloadUserEventsCsv);
}

function* generateEventPrices({ payload }: Interfaces.GenerateEventPricesAction) {
  try {
    const { data } = yield call(Api.generateEventPrices, payload);
    yield put(ActionTypes.generateEventPrices.success(data));
  } catch (e) {
    handleError(e);
    yield put(ActionTypes.generateEventPrices.failure(e));
  }
}

function* generateEventPricesSaga() {
  yield takeLatest(ActionTypes.GENERATE_EVENT_PRICES, generateEventPrices);
}

export const eventsSaga = function* rootSaga() {
  yield all([
    fork(createEventSaga),
    fork(updateEventSaga),
    fork(deleteEventSaga),
    fork(getEventsListSaga),
    fork(getDetailedEventsSaga),
    fork(getSingleEventSaga),
    fork(getOneEventSaga),
    fork(getContributorSaga),
    fork(getContributorEventsSaga),
    fork(getUserDataSaga),
    fork(postCommentSaga),
    fork(patchReportSaga),
    fork(deleteCommentSaga),
    fork(postPhoneticSaga),
    fork(listPhoneticsSaga),
    fork(deletePhoneticSaga),
    fork(rejectEventSaga),
    fork(putEventOnlineSaga),
    fork(putEventOfflineSaga),
    fork(rejectEventDetailSaga),
    fork(putEventDetailOnlineSaga),
    fork(putEventDetailOfflineSaga),
    fork(downloadUserEventsCsvSaga),
    fork(generateEventPricesSaga),
  ]);
};
