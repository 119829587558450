import { Reducer } from 'react';
import { withLoadable } from 'utils/hoc';
import * as Action from './Auth.actions';
import { AuthActionTypes, AuthState } from './Auth.interfaces';

const initialState: AuthState = {
  loading: false,
  error: null,
  loggedIn: false,
  resetPasswordSuccess: false,
};

const reducer: Reducer<AuthState, AuthActionTypes> = (state = initialState, action) => {
  switch (action.type) {
    case Action.POST_TOKEN_VERIFY_SUCCESS:
    case Action.GET_EMAIL_ACTIVATE_SUCCESS:
    case Action.POST_AUTH_LOGIN_SUCCESS:
    case Action.POST_SOCIAL_AUTH_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
      };
    }

    case Action.POST_EMAIL_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: true,
      };
    case Action.CLEAR_EMAIL_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordSuccess: false,
      };

    case Action.LOG_OUT: {
      localStorage.clear();
      return {
        ...state,
        loggedIn: false,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export const authReducer = withLoadable([
  Action.POST_SOCIAL_AUTH,
  Action.POST_AUTH_LOGIN,
  Action.POST_AUTH_REGISTER,
  Action.POST_EMAIL_RESET_PASSWORD,
  Action.POST_RESET_PASSWORD,
  Action.GET_EMAIL_ACTIVATE,
  Action.POST_TOKEN_VERIFY,
])(reducer);
