import React from 'react';
import { useTranslation } from 'react-i18next';
import { RenderMenuList } from 'components/MenuList';
import { ROUTES } from 'constant';
import { ADMIN, BASE_USER, CONTRIBUTOR, IMenuList, SUPER_ADMIN } from 'interfaces';
import { BsPlusCircle } from 'react-icons/bs';
import defaultTheme from '../../../assets/styles';
import { StyledMainMenu } from './Header.style';

export const MainMenu = () => {
  const { t } = useTranslation();
  const MenuList: IMenuList[] = [
    {
      icon: <BsPlusCircle size="1em" color={defaultTheme.primary[0]} />,
      key: 'CREATE_EVENT',
      exact: true,
      to: `${ROUTES.PROFILE.AGENT_ACCOUNT_SETTINGS_PAGE}${ROUTES.PROFILE.ENTITIES_EVENTS}${ROUTES.PROFILE.CREATE_EVENT}`,
      permissions: [BASE_USER, CONTRIBUTOR, ADMIN, SUPER_ADMIN],
      title: t('Create Event'),
    },
  ];

  return <StyledMainMenu>{RenderMenuList(MenuList)}</StyledMainMenu>;
};
