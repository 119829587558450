import { call, put, takeLatest, fork, all } from 'redux-saga/effects';
import * as ActionTypes from './SEOLinking.actions';
import * as ActionInterface from './SEOLinking.interfaces';
import Api from './SEOLinking.api';

function* getSEOLinks({}: ActionInterface.GetSEOLinksAction) {
  try {
    const { data } = yield call(Api.getSEOLinks);
    yield put(ActionTypes.getSEOLinks.success(data));
  } catch (e) {
    yield put(ActionTypes.getSEOLinks.failure(e));
  }
}

function* getSEOLinksSaga() {
  yield takeLatest(ActionTypes.GET_SEO_LINKS, getSEOLinks);
}

export const seoLinksSaga = function* rootSaga() {
  yield all([fork(getSEOLinksSaga)]);
};
