import React, { Fragment, ReactNode, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Layout as LayoutWrapper } from 'antd';
import { ROUTES } from 'constant';
import { postTokenVerify } from 'modules/Auth/Auth.actions';
import { Header } from './Header/Header';
import Footer from './Footer/Footer';
import { Player } from './Player';
import { EventsState } from 'modules/Events/Event.interfaces';
import Branding from './Branding';

interface ILayout {
  children?: ReactNode;
  location?: Location;
}

const { Content } = LayoutWrapper;

const checkAvailableLocation = (location: any) => {
  if (location.location) {
    return Object.values(ROUTES.AUTH).some((i) => location.location.pathname.includes(i));
  }
  return Object.values(ROUTES.AUTH).some((i) => location.pathname.includes(i));
};

const RenderLogUserTemplate = ({ children }: { children?: ReactNode }) => {
  const {
    playerList: { list, account },
  } = useSelector(({ eventsReducer }: { eventsReducer: EventsState }) => eventsReducer);

  const PlayerContainer = useCallback(() => Boolean(list.length) && Boolean(account.length) && <Player />, [
    list,
    account,
  ]);

  return (
    <Fragment>
      <Header />
      <Content>{children}</Content>
      <Footer />
      {PlayerContainer()}
    </Fragment>
  );
};

export const IframeLayout = ({ children }: ILayout) => {
  const {
    playerList: { list, account },
  } = useSelector(({ eventsReducer }: { eventsReducer: EventsState }) => eventsReducer);
  const PlayerContainer = useCallback(() => Boolean(list.length) && Boolean(account.length) && <Player />, [
    list,
    account,
  ]);
  return (
    <Fragment>
      <Content>{children}</Content>
      {PlayerContainer()}
      <Branding />
    </Fragment>
  );
};
export const AuthLayout = ({ children }: ILayout) => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(postTokenVerify.request({ data: { token } }));
    }
  }, []);

  return (
    <>
      {checkAvailableLocation(location) ? (
        <Content>{children}</Content>
      ) : (
        <RenderLogUserTemplate>{children}</RenderLogUserTemplate>
      )}
    </>
  );
};
