import { createApiAction } from 'utils/helpers';
import { IEntitiesPageFilters, IEventsPageState, IUsersPageFilters } from './AdminPanel.interfaces';
import { IEntity } from '../../interfaces';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';

export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_LATEST_CREATED_TAG = 'CLEAR_LATEST_CREATED_TAG';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

export const GET_ENTITIES = 'GET_ENTITIES';
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS';

export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_PAST_EVENTS = 'GET_PAST_EVENTS';
export const GET_PAST_EVENTS_SUCCESS = 'GET_PAST_EVENTS_SUCCESS';

export const GET_UPCOMING_EVENTS = 'GET_UPCOMING_EVENTS';
export const GET_UPCOMING_EVENTS_SUCCESS = 'GET_UPCOMING_EVENTS_SUCCESS';
export const DEACTIVATE_USER = 'DEACTIVATE_USER';
export const DEACTIVATE_ENTITY = 'DEACTIVATE_ENTITY';
export const DEACTIVATE_ENTITY_SUCCESS = 'DEACTIVATE_ENTITY_SUCCESS';
export const ACTIVATE_ENTITY = 'ACTIVATE_ENTITY';
export const ACTIVATE_ENTITY_SUCCESS = 'ACTIVATE_ENTITY_SUCCESS';
export const CREATE_ENTITY = 'CREATE_ENTITY';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';

export const POST_CREATE_USER = 'POST_CREATE_USER';
export const POST_CREATE_USER_SUCCESS = 'POST_CREATE_USER_SUCCESS';

export const PATCH_UPDATE_USER = 'PATCH_UPDATE_USER';
export const PATCH_UPDATE_USER_SUCCESS = 'PATCH_UPDATE_USER_SUCCESS';

export const GET_ALL_TAGS = 'GET_ALL_TAGS';
export const GET_ALL_TAGS_SUCCESS = 'GET_ALL_TAGS_SUCCESS';

export const DELETE_TAG = 'DELETE_TAG';
export const DELETE_TAG_SUCCESS = 'DELETE_TAG_SUCCESS';

export const CREATE_TAG = 'CREATE_TAG';
export const CREATE_TAG_SUCCESS = 'CREATE_TAG_SUCCESS';

export const UPDATE_TAG = 'UPDATE_TAG';
export const UPDATE_TAG_SUCCESS = 'UPDATE_TAG_SUCCESS';

export const GET_ZONES = 'GET_ZONES';
export const GET_ZONES_SUCCESS = 'GET_ZONES_SUCCESS';

export const GET_PACKS = 'GET_PACKS';
export const GET_PACKS_SUCCESS = 'GET_PACKS_SUCCESS';

export const CREATE_PACK = 'CREATE_PACK';

export const PATCH_PACK = 'PATCH_PACK';

export const DELETE_PACK = 'DELETE_PACK';

export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_ALL_PLANS_SUCCESS = 'GET_ALL_PLANS_SUCCESS';

export const CREATE_PLAN = 'CREATE_PLAN';

export const PATCH_PLAN = 'PATCH_PLAN';

export const DELETE_PLAN = 'DELETE_PLAN';

export const UPLOAD_CSV = 'UPLOAD_CSV';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';

export const ADD_ENTITY_CREDITS = 'ADD_ENTITY_CREDITS';
export const ADD_ENTITY_CREDITS_SUCCESS = 'ADD_ENTITY_CREDITS_SUCCESS';

export const GET_ALL_COMMENTS = 'GET_ALL_COMMENTS';
export const GET_ALL_COMMENTS_SUCCESS = 'GET_ALL_COMMENTS_SUCCESS';

export const ADMIN_DELETE_COMMENT = 'ADMIN_DELETE_COMMENT';
export const CLEAR_CONTRIBUTOR_TRANSCRIPTION = 'CLEAR_CONTRIBUTOR_TRANSCRIPTION';

export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';

export const GET_ENTITY_TO_EDIT = 'GET_ENTITY_TO_EDIT';
export const GET_ENTITY_TO_EDIT_SUCCESS = 'GET_ENTITY_TO_EDIT_SUCCESS';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';

export const SET_EVENTS_PAGE_STATE = 'SET_EVENTS_PAGE_STATE';
export const setEventsPageState = (state: IEventsPageState) => {
  return {
    type: SET_EVENTS_PAGE_STATE,
    payload: state,
  };
};
export const SET_USERS_PAGE_FILTERS = 'SET_USERS_PAGE_FILTERS';
export const setUsersPageFilters = (filters: IUsersPageFilters) => {
  return {
    type: SET_USERS_PAGE_FILTERS,
    payload: filters,
  };
};

export const SET_ENTITIES_PAGE_FILTERS = 'SET_ENTITIES_PAGE_FILTERS';
export const setEntitiesPageFilters = (filters: IEntitiesPageFilters) => {
  return {
    type: SET_ENTITIES_PAGE_FILTERS,
    payload: filters,
  };
};

export const SET_ENTITY_TO_EDIT = 'SET_ENTITY_TO_EDIT';
export const setEntityToEdit = (entity: IEntity) => {
  return {
    type: SET_ENTITY_TO_EDIT,
    payload: entity,
  };
};

export const EVENT_STATUS_UPDATE_SUCCESS = 'EVENT_STATUS_UPDATE_SUCCESS';
export const VERIFY_ALIAS = 'VERIFY_ALIAS';
export const VERIFY_ALIAS_SUCCESS = 'VERIFY_ALIAS_SUCCESS';
export const VERIFY_ALIAS_FAILURE = 'VERIFY_ALIAS_FAILURE';

export const getUser = createApiAction(GET_USER);
export const getUsers = createApiAction(GET_USERS);
export const getEntities = createApiAction(GET_ENTITIES);
export const deactivateUser = createApiAction(DEACTIVATE_USER);
export const deactivateEntity = createApiAction(DEACTIVATE_ENTITY);
export const activateEntity = createApiAction(ACTIVATE_ENTITY);
export const createEntity = createApiAction(CREATE_ENTITY);

export const postCreateUser = createApiAction(POST_CREATE_USER);
export const patchUpdateUser = createApiAction(PATCH_UPDATE_USER);
export const getPastEvents = createApiAction(GET_PAST_EVENTS);
export const getUpcomingEvents = createApiAction(GET_UPCOMING_EVENTS);
export const getAllTags = createApiAction(GET_ALL_TAGS);
export const deleteTag = createApiAction(DELETE_TAG);
export const createTag = createApiAction(CREATE_TAG);
export const updateTag = createApiAction(UPDATE_TAG);
export const getZones = createApiAction(GET_ZONES);
export const getPacks = createApiAction(GET_PACKS);
export const createPack = createApiAction(CREATE_PACK);
export const updatePack = createApiAction(PATCH_PACK);
export const deletePack = createApiAction(DELETE_PACK);
export const getPlans = createApiAction(GET_ALL_PLANS);
export const createPlan = createApiAction(CREATE_PLAN);
export const updatePlan = createApiAction(PATCH_PLAN);
export const deletePlan = createApiAction(DELETE_PLAN);
export const uploadCsv = createApiAction(UPLOAD_CSV);
export const addEntityCredits = createApiAction(ADD_ENTITY_CREDITS);
export const getAllComments = createApiAction(GET_ALL_COMMENTS);
export const deleteComment = createApiAction(ADMIN_DELETE_COMMENT);
export const getEntityToEdit = createApiAction(GET_ENTITY_TO_EDIT);
export const verifyAlias = createApiAction(VERIFY_ALIAS);
