import { IHomePageState, IIframeParams } from './Global.interfaces';

export const SET_HOME_PAGE_STATE = 'SET_HOME_PAGE_STATE';

export const setHomePageState = (state: IHomePageState) => {
  return {
    type: SET_HOME_PAGE_STATE,
    payload: state,
  };
};

export const UPDATE_IFRAME_PARAMS = 'UPDATE_IFRAME_PARAMS';

export const updateIframeParams = (iframeParams: IIframeParams) => {
  return {
    type: UPDATE_IFRAME_PARAMS,
    payload: iframeParams,
  };
};
